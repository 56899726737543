<template>
  <v-row>
    <v-col cols="12" md="9">
      <v-card>
        <v-card-title>{{ $t('routes.contenttypes.index') }}</v-card-title>
        <div v-if="contenttypes.length == 0">
          <v-divider />
          <v-card-text>
            {{ $t('table.no_data') }}
          </v-card-text>
        </div>
        <v-row v-else no-gutters>
          <v-col cols="2">
            <v-divider />

            <v-list>
              <v-list-item-group v-model="selected_content_type" mandatory>
                <v-list-item v-for="contenttype in contenttypes" :key="contenttype.id" link>
                  <v-list-item-title>{{ contenttype[$i18n.locale] }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col>
            <Datatable
              v-if="refresh_key > 0"
              :key="refresh_key"
              :get_endpoint="getActiveApiURL"
              edit_route="contenttype_entries.edit"
              :item_on_click="
                item => {
                  if (hasVueRoute('contenttype_entries.edit')) {
                    this.$router.push({
                      name: 'contenttype_entries.edit',
                      params: { id: item.id, api_identifier: item.contenttype.api_identifier },
                    })
                  }
                }
              "
              :sort_by="getSortBy"
              :delete_endpoint="getActiveApiURL"
              :header_items="headers"
              user_friendly_identifier="title"
              :type="contenttypes[selected_content_type][$i18n.locale]"
              style="border-left: 1px solid rgba(94, 86, 105, 0.14)"
            >
              <template v-slot:[`item.title`]="{ item }">
                <template v-if="typeof item.title == 'object'">
                  {{ item.title[$i18n.locale] }}
                </template>
                <template v-else>
                  {{ item.title }}
                </template>
              </template>
            </Datatable>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" md="3">
      <v-card>
        <v-card-text>
          <v-btn
            @click="$router.push('/contenttype/' + getActiveContenttype['api_identifier'] + '/entries/add')"
            :disabled="loading || contenttypes.length == 0"
            :loading="loading"
            color="primary"
            type="submit"
            block
            small
          >
            <v-icon class="mr-1">{{ mdiPlus }}</v-icon>

            {{ $t('common.add') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiArrowULeftTop, mdiPlus } from '@mdi/js'
import Datatable from '@/components/Datatable/Datatable.vue'

export default {
  components: {
    Datatable,
  },
  beforeMount() {
    this.$http('contenttypes').then(res => {
      this.formatContenttypes(res.data.data)
      this.contenttypes = res.data.data

      for (let i = 0; i < this.contenttypes.length; i++) {
        if (this.contenttypes[i].api_identifier === this.$store.state.app.selectedContenttype) {
          this.selected_content_type = i
        }
      }
    })
  },
  data() {
    return {
      loading: false,
      open_navigation_drawer: false,
      selected_content_type: -1,
      headers: [],
      contenttypes: [],
      entries: [],
      refresh_key: 0,
      //icons
      mdiArrowULeftTop,
      mdiPlus,
    }
  },
  methods: {
    formatContenttypes(cts) {
      cts.forEach(ct => {
        ct.contenttype_translations.forEach(translation => {
          ct[translation['locale']] = translation['name']
        })
      })
    },
    getHeaders() {
      let headers = [
        {
          text: 'label.title',
          value: 'title',
          search_type: 'text',
          data_format: 'translated',
          sortable: true,
        },
      ]
      let index_header = {
        text: 'label.id',
        width: '100',
        align: 'start',
        value: 'id',
        search_type: 'number',
        sortable: true,
      }
      if (this.getActiveContenttype.is_sortable) {
        index_header.text = 'label.index'
        index_header.value = 'priority_index'
        index_header.align = 'center'
        headers.push(index_header)
      } else {
        headers.unshift(index_header)
      }

      return headers
    },
  },
  computed: {
    getActiveApiURL() {
      if (this.selected_content_type > -1) {
        return 'contenttypes/' + this.contenttypes[this.selected_content_type].api_identifier + '/entries'
      }
      return ''
    },
    getActiveContenttype() {
      if (this.selected_content_type > -1) {
        return this.contenttypes[this.selected_content_type]
      }
      return ''
    },
    getSortBy() {
      if (this.getActiveContenttype.is_sortable) {
        return ['priority_index']
      }
      return ['id']
    },
  },
  watch: {
    selected_content_type: function () {
      this.$store.commit('app/setSelectedContenttype', this.contenttypes[this.selected_content_type].api_identifier)
      //set headers
      let headers = [
        {
          text: 'label.title',
          value: 'title',
          search_type: 'text',
          data_format: 'translated',
          sortable: true,
        },
      ]
      let index_header = {
        text: 'label.id',
        width: '100',
        align: 'start',
        value: 'id',
        search_type: 'number',
        sortable: true,
      }
      if (this.getActiveContenttype.is_sortable) {
        index_header.text = 'label.index'
        index_header.value = 'priority_index'
        index_header.align = 'center'
        headers.push(index_header)
      } else {
        headers.unshift(index_header)
      }
      this.headers = headers

      this.refresh_key++
    },
  },
}
</script>