var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('routes.contenttypes.index')))]),(_vm.contenttypes.length == 0)?_c('div',[_c('v-divider'),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('table.no_data'))+" ")])],1):_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-divider'),_c('v-list',[_c('v-list-item-group',{attrs:{"mandatory":""},model:{value:(_vm.selected_content_type),callback:function ($$v) {_vm.selected_content_type=$$v},expression:"selected_content_type"}},_vm._l((_vm.contenttypes),function(contenttype){return _c('v-list-item',{key:contenttype.id,attrs:{"link":""}},[_c('v-list-item-title',[_vm._v(_vm._s(contenttype[_vm.$i18n.locale]))])],1)}),1)],1)],1),_c('v-col',[(_vm.refresh_key > 0)?_c('Datatable',{key:_vm.refresh_key,staticStyle:{"border-left":"1px solid rgba(94, 86, 105, 0.14)"},attrs:{"get_endpoint":_vm.getActiveApiURL,"edit_route":"contenttype_entries.edit","item_on_click":function (item) {
                if (_vm.hasVueRoute('contenttype_entries.edit')) {
                  this$1.$router.push({
                    name: 'contenttype_entries.edit',
                    params: { id: item.id, api_identifier: item.contenttype.api_identifier },
                  })
                }
              },"sort_by":_vm.getSortBy,"delete_endpoint":_vm.getActiveApiURL,"header_items":_vm.headers,"user_friendly_identifier":"title","type":_vm.contenttypes[_vm.selected_content_type][_vm.$i18n.locale]},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
              var item = ref.item;
return [(typeof item.title == 'object')?[_vm._v(" "+_vm._s(item.title[_vm.$i18n.locale])+" ")]:[_vm._v(" "+_vm._s(item.title)+" ")]]}}],null,true)}):_vm._e()],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-card',[_c('v-card-text',[_c('v-btn',{attrs:{"disabled":_vm.loading || _vm.contenttypes.length == 0,"loading":_vm.loading,"color":"primary","type":"submit","block":"","small":""},on:{"click":function($event){return _vm.$router.push('/contenttype/' + _vm.getActiveContenttype['api_identifier'] + '/entries/add')}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.mdiPlus))]),_vm._v(" "+_vm._s(_vm.$t('common.add'))+" ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }